import {Controller} from 'stimulus'

export default class extends Controller {
  static targets = ["dropdown"]

  toggle(event) {
    if (event.target.open === false) {
      return
    }

    this.dropdownTargets
      .filter(target => target !== event.target)
      .forEach(
        details => details.open = false
      )
  }
}
