import './style/bulma.scss'
import './style/style.scss'


import { Application } from 'stimulus'
import { definitionsFromContext } from 'stimulus/webpack-helpers'

if (process.env.NODE_ENV === 'development') {
  require('./style/debug.scss')
}

const application = Application.start()
const context = require.context('./controllers', true, /\.js$/)
application.load(definitionsFromContext(context))
