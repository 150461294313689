import 'photoswipe/dist/photoswipe.css'
import 'photoswipe/dist/default-skin/default-skin.css'
import { Controller } from 'stimulus'
import supportsWebP from 'supports-webp'

import template from './pswp.html'

const LANDSCAPE_RENDER_THRESHOLDS = [
  320,
  640,
  768,
  1024,
  1280,
  1680,
  1920,
  2560,
  3840
]

const PORTRAIT_RENDER_THRESHOLDS = [
  360,
  480,
  600,
  800,
  1080,
  1280,
  1440,
  2160,
  2880
]

function createContainer () {
  const container = document.createElement('div')
  container.innerHTML = template
  document.documentElement.appendChild(container)
  return [
    container.children[0],
    () => { document.documentElement.removeChild(container) }
  ]
}

const POINTER_EVENT = typeof window.PointerEvent !== 'undefined' ? 'pointerup' : 'click'

function getImageSize (width, height, maxWidth, maxHeight) {
  const isLandscape = width > height
  const aspectRatio = isLandscape ? width / height : height / width
  const thresholds = isLandscape ? LANDSCAPE_RENDER_THRESHOLDS : PORTRAIT_RENDER_THRESHOLDS
  const filter = isLandscape ? 'width' : 'height'
  const maxSize = isLandscape ? maxWidth : maxHeight
  const optimalImageSize = window.devicePixelRatio * (isLandscape ? window.innerWidth : window.innerHeight)
  const optimalFilterImageSize = thresholds.find(renderSize => renderSize > optimalImageSize) || thresholds[thresholds.length - 1]
  const newSize = optimalFilterImageSize > maxSize ? maxSize : optimalFilterImageSize
  const newOrthogonalSize = Math.round(newSize / aspectRatio)
  return [
    newSize === maxSize ? [] : `${filter}-${newSize}`,
    isLandscape
      ? {w: newSize, h: newOrthogonalSize}
      : {w: newOrthogonalSize, h: newSize}
  ]
}

export default class extends Controller {
  static targets = ['image']

  initialize () {
    const modules = Promise.all([
      import('photoswipe/dist/photoswipe'),
      import('photoswipe/dist/photoswipe-ui-default')
    ])
    this.createPhotoSwipe = async function (container, images, options) {
      const [PhotoSwipe, Theme] = await modules
      return new PhotoSwipe(container, Theme, images, options)
    }
  }

  async connect () {
    const doesSupportWebP = await supportsWebP
    const defaultFilters = doesSupportWebP ? ['format-webp'] : []
    this.images = Array.from(this.imageTargets).map(imgFigure => {
      const img = imgFigure.querySelector('[data-pswp-image-source]')

      // enable focus so that images can be opened via the keyboard
      img.setAttribute('tabindex', '0')

      const id = imgFigure.dataset.pswpImageId
      const width = parseInt(img.getAttribute('width'))
      const height = parseInt(img.getAttribute('height'))
      const [maxWidth, maxHeight] = img.dataset.pswpImageDimensions.split('x').map(d => parseInt(d))
      const [sizeFilter, dimensions] = getImageSize(width, height, maxWidth, maxHeight)
      const renderFilter = defaultFilters.concat(sizeFilter).join('|')
      return {
        el: img,
        ...dimensions,
        pid: id,
        src: `${location.origin}/api/content/images/${id}/render?filter=${renderFilter}`,
        msrc: img.getAttribute('src'),
        alt: img.getAttribute('alt'),
        title: img.getAttribute('title')
      }
    })
    this.imageTargets.forEach(target => { target.classList.add('does-open') })
    this.handleClick.bound = this.handleClick.bind(this)
    this.handleKey.bound = this.handleKey.bind(this)
    this.element.addEventListener(POINTER_EVENT, this.handleClick.bound)
    this.element.addEventListener('keydown', this.handleKey.bound)
  }

  disconnect () {
    this.element.removeEventListener(POINTER_EVENT, this.handleClick.bound)
    this.element.removeEventListener('keydown', this.handleKey.bound)
  }

  handleKey (event) {
    if (event.code === 'Enter' || event.keyCode === 13) {
      this.openFromTarget(event.target)
    }
  }

  handleClick (event) {
    if (event.button === 0) {
      this.openFromTarget(event.target)
    }
  }

  openFromTarget (target) {
    const imageIndex = this.images.findIndex(image => image.el === target)
    if (imageIndex !== -1) {
      this.open(imageIndex)
    }
  }

  async open (imageIndex = 0) {
    const [container, removeContainer] = createContainer()
    const options = {
      preload: [1, 3],
      shareEl: false,
      index: imageIndex,
      getThumbBoundsFn: index => {
        const pageYScroll = window.pageYOffset || document.documentElement.scrollTop
        const rect = this.images[index].el.getBoundingClientRect()
        return {x: rect.left, y: rect.top + pageYScroll, w: rect.width}
      }
    }
    const photoSwipe = await this.createPhotoSwipe(container, this.images, options)
    photoSwipe.listen('destroy', () => {
      removeContainer()
      this.images[imageIndex].el.focus()
    })
    photoSwipe.init()
  }
}
