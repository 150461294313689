import { Controller } from 'stimulus'

export default class extends Controller {
  async connect () {
    try {
      const config = JSON.parse(this.element.querySelector('script').textContent)
      const initForm = await import('../order-form/index').then(m => m.default)
      initForm(this.element, config)
    } catch (e) {
      console.error(e)
      this.element.innerHTML = `<p class="notification is-danger">Beim Laden des Bestellformulars ist ein Fehler aufgetreten. Bitte melde diesen Fehler an uns.</p>`
    }
  }
}
